import {firestore} from "../firebase/clientApp";

export default function findAnswered(id, elementPlaying, user){
    return new Promise((resolve, reject) => {
        var docRef = firestore.collection("users/plesh/events/" + id + "/queue/" + elementPlaying.id + "/answers").doc(user.uid);

        docRef.get().then((doc) => {
            resolve(doc.exists);
        }).catch((error) => {
            console.log("Error getting document:", error);
            reject(error);
        });
    });
}

export function findAnsweredPhoto(id, elementPlaying, user){
    return new Promise((resolve, reject) => {
        var docRef = firestore.collection("users/plesh/events/" + id + "/queue/" + elementPlaying.id + "/answers")
            .where('user', '==', user.uid)
            .get()

        docRef.then((doc) => {
            resolve(!doc.empty);
        }).catch((error) => {
            console.log("Error getting document:", error);
            reject(error);
        });
    });
}