import React, {useEffect, useState} from "react";
import {auth, firebase, firestore} from "../firebase/clientApp";
import VoteContent from "../components/VoteContent";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import axios from "axios";
import {API_URL} from "../config";

export default function Vote() {
    const {id} = useParams()
    let navigate = useNavigate()

    const [user, setUser] = useState(null);
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))
    const [userData, setUserData] = useState({displayName: '', teamName: '', customField: ''})

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!localStorage.getItem('tutorial')) {
            console.log("Tutorial non fatto")
            navigate('/tutorial')
        }
    }, [])

    async function loginUser() {
        await auth.signInAnonymously().then(() => {}).catch(alert);
    }

    useEffect(async () => {
        setLoading(true)
        await loginUser()
        firebase.auth().onAuthStateChanged(async (user) => {
            console.log("user.uid:",user.uid)
            setUser(user);
            await axios.post(`${API_URL}/events/${id}/users`,{user: user.uid})
                .then(res =>
                    setUserData({displayName: res.data.displayName ?? '', teamName: res.data.teamName ?? '', customField: res.data.customField ?? ''})
                )
                .catch()
        })
        setLoading(false)
    }, [])

    if (loading) return <></>

    return (
        <Box>
            {(event && !eventLoading && !eventError) &&
                <Container maxWidth={'sm'} className={'vote'} style={{maxHeight: '100%'}}>
                    <VoteContent user={user} event={event} loading={loading} userData={userData} setUserData={setUserData}/>
                </Container>
            }
        </Box>
    )
}
