import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, IconButton, Stack, Typography} from "@mui/material";
import questionType from "../assets/data/typeMap";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";
import {useDocument} from "react-firebase-hooks/firestore";
import pleshDark from "../theme/pleshDark";


export const PinnedQuestions = ({uid, questions, userData}) => {
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [customFieldUser, customFieldUserLoading, customFieldUserError] = useDocument(firestore.doc(`users/plesh/events/${id}/users/${uid}`))

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
    }, [selected]);

    function getElementPlaying() {
        firestore.collection('users/plesh/events/'+id+'/queue').doc(selected).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }

    return (
        <Box mt={3}>
            {selected !== '' &&
                <Dialog open={selected !== ''} onClose={() => setSelected('')} maxWidth={'md'}
                        fullWidth={true}
                        fullScreen={true}//elementPlaying.tipo === 'color'}
                >
                <DialogContent id={'a'+selected} /*id={'pinnedQuestionDialogBg'}*/>
                    <Box sx={{textAlign:'right'}}>
                        <IconButton onClick={() => setSelected('')}
                                    sx={{
                                        color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : ''
                                    }}
                        >
                            <Close/>
                        </IconButton>
                    </Box>
                    {elementPlaying.id !== "sFb5qyJ5WR0SlKQfD3Mr" &&
                        <><Typography textAlign={'center'} variant={'h5'}
                                 sx={{color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : ''}}
                        >
                            {elementPlaying.domanda}
                        </Typography><br/></>}
                    <QuestionSwitch elementPlaying={elementPlaying}/>
                </DialogContent>
            </Dialog>}
            <Stack spacing={2}>
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) => {
                            return <Button key={i} variant={'home-question'}
                                           //startIcon={questionType[q.data().tipo].icon}
                                           sx={{
                                               background: q.data().tipo === 'color' ? q.data().color : '',
                                               color: q.data().tipo === 'color' ? pleshDark.palette.getContrastText(q.data().color) : ''
                                            }}
                                           onClick={() => setSelected(q.id)}
                                           disabled={q.id === 'sFb5qyJ5WR0SlKQfD3Mr'
                                               && (customFieldUser?.data()?.customField !== 'typeform')}
                            >
                                {q.data().domanda}
                            </Button>
                        }
                    )
                }
            </Stack>
        </Box>
    )
}