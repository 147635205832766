import React from "react";
import {Button} from "@mui/material";
import {firebase} from "../../../firebase/clientApp";

export const AltroQuestion = () => {
    const uid = firebase.auth().currentUser.uid

    return(<>
            <Button variant={'home-question'}
                    href={`https://rmou3qne4s8.typeform.com/to/ScMUo280#user_id=${uid}`}
                    target={'_blank'}>
                QUESTIONARIO
            </Button>
    </>

  )
}