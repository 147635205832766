import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    Collapse,
    Container,
    Stack,
    Typography,
    CircularProgress,
    useTheme,
    Card,
    CardContent
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import axios from "axios";
import {useParams} from "react-router-dom";
import {API_URL} from "../../../config";
import {firebase} from "../../../firebase/clientApp";
import {CustomAlert} from "../../shared/CustomAlert";
import scattaFoto from "../../../assets/images/headers/SCATTA-UNA-FOTO CON.png"
import missioneCompiuta from "../../../assets/images/headers/MISSIONE-COMPIUTA.png"

export const SendPhotoQuestionContest = ({elementId, answered}) => {
    const {id} = useParams()
    const [foto, setFoto] = useState(null)
    const [url, setUrl] = useState('')
    const [_answered, setAnswered] = useState(answered)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const theme = useTheme()

    useEffect(() => {
        setAnswered(answered)
    }, [answered])

    const handleCompileFoto = (e) => {
        const [f] = e.target.files
        setFoto(f)
        setUrl(URL.createObjectURL(f))
    }

    const sendPhoto = async () => {
        setSending(true)
        const formData = new FormData();
        formData.append('file', foto);
        formData.append('user', firebase.auth().currentUser.uid);
        await axios.post(`${API_URL}/answer/${id}/${elementId}/photo`, formData)
            .then(() => {
                setSuccess(true)
                setAnswered(true)
                setFoto(null)
                setUrl('')
            })
            .catch(() =>{})
        setSending(false)
    }

    return(
      <Container>
          <CustomAlert open={success} setOpen={setSuccess} severity={'success'} message={'Foto inviata con successo'}/>
          {_answered ?
              <Stack alignItems={'center'}>
                  <img src={missioneCompiuta} width={'90%'}/>
                  <Box p={2} backgroundColor={'rgba(255,255,255,0.35)'} borderRadius={'8px'}>
                      <Typography>
                          IT'S  A MATCH!!! 🫶🏻
                          <br/>Grazie per avere giocato con noi. Le tue risposte sono risultate comptabili con più di un'azienda partner. Queste, se lo riterranno necessario, ti contatteranno anche solo per una chiacchierata.
                          <br/>Ora mostra lo screen al punto di ritiro e riscatta la tua bag.
                          <br/>Buon FBN 2k24! 🎉
                      </Typography>
                  </Box>
              </Stack>
              :
              <form style={{padding: '0 0'}}>
                  <Stack alignItems={'center'} direction={"column"} spacing={4}>
                      <Stack alignItems={'center'}>
                          <img src={scattaFoto} width={'90%'}/>
                          <Typography textAlign={'center'}>Scatta una foto 📷 con [challenge randomica] e inviacela!</Typography>
                      </Stack>
                      <Box sx={{
                          width: '80%',
                          aspectRatio: '1/1',
                          fontWeight: '600',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '2rem',
                          border: `2px dashed ${theme.palette.primary.main}`,
                          padding: '1rem'
                      }} component="label">
                          {foto === null ?
                              <Box>
                                  <Typography variant={'h5'} color={'#eceff1'}>Scegli una foto</Typography>
                                  <input type="file" hidden onChange={handleCompileFoto}/>
                              </Box> :
                              <Box>
                                  <Box sx={{width: '11rem', height: '11rem', margin: 'auto'}}>
                                      <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={url}/>
                                  </Box>
                                  <Box>
                                      <Button startIcon={<Delete/>} onClick={() => {
                                          setTimeout(() => {
                                              setFoto(null)
                                              setUrl('')
                                          }, 100)
                                      }}>
                                          cancella
                                      </Button>
                                  </Box>
                              </Box>
                          }
                      </Box>
                      <Box width={'100%'} sx={{textAlign: 'center'}}>
                          {sending ? <CircularProgress/> : <Button onClick={sendPhoto} disabled={foto === null}
                                                                   variant={'default'}>
                              Invia
                          </Button>}
                      </Box>
                  </Stack>
              </form>}
      </Container>
  )
}
