import './App.css';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import pleshDark from "./theme/pleshDark";
import Vote from "./pages/Vote";
import React, {useState} from "react";
import {Box, Button, CssBaseline, Slide, Stack, ThemeProvider, Typography} from "@mui/material";
import Home from "./pages/Home";
import cta from "../src/assets/images/headers/cta.png"
import rules from "../src/assets/images/headers/REGOLE-DEL-GIOCO.png"

function Tutorial() {
    let navigate = useNavigate()

    const steps = [
        {img: cta, button: 'Voglio la bag', description: <><b>FBN IT'S MATCHING TIME! 🎉</b><br/>Fai match, incontra nuovi amici e scopri le aziende giuste per te! In palio per te l'esclusiva Bag dell'evento!</>},
        {img: rules, button: 'Letsgoski!', description: <>Preparati a metterti alla prova! <br/>Rispondi alle domande e completa le challenge. Solo arrivando alla fine del gioco sbloccherai il premio esclusivo: la bag (e tutto quello che contiene 😉)  celebrativa dell'FBN 2k24</>}
    ]
    const [animate, setAnimate] = useState(true)
    const [step, setStep] = useState(0)

    function handleTutorialDone() {
        setAnimate(false)
        if(step === steps.length - 1) {
            localStorage.setItem('tutorial', 'true')
            navigate('/')
        } else {
            setStep(step+1)
        }

        setTimeout(() => {
            setAnimate(true)
        }, 400)
    }

    return (<Stack pt={'20%'} alignItems={'center'} justifyContent={'center'}>
        <Slide in={animate} direction={'right'} unmountOnExit>
            <Stack px={3} alignItems={'center'}>
                <img src={steps[step]?.img} width={'300px'}/>
                <Typography>
                    {steps[step]?.description}
                </Typography>
                <Box mt={'20%'} width={'100%'}>
                    <Button sx={{marginTop: 'auto'}} variant={'home-question'} onClick={handleTutorialDone}>
                        {steps[step]?.button}
                    </Button>
                </Box>
            </Stack>
        </Slide>
    </Stack>);
}

function App() {
    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <div className="App">
                <Routes>
                    <Route path='/' element={<Navigate to={'/vote/vJQU78xDeppLGeU0EBtS'}/>}/>
                    <Route path='/tutorial' element={<Tutorial/>}/>
                    <Route path='/vote/:id' element={<Vote/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
