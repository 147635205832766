export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#E44D7D', '#E44D7D'],
        home_question: ['#E44D7D', '#E44D7D'],
        applausometro: ['#a993f1', '#E5448A'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#E44D7D', '#E44D7D'],
        qna: ['#E44D7D', '#E44D7D'],
    },
    primary: '#E44D7D',
    secondary: '#263238',
    accent: '#E44D7D',
    text: '#1D1D1D',
    background: 'radial-gradient(circle at 20% 20%, rgba(147, 112, 219, 0.9), transparent 70%), ' +
        'radial-gradient(circle at 80% 80%, rgba(255, 105, 180, 0.9), transparent 70%), #E78AC3',
    poll:{
        default:"#a993f1",
        answered:"#E5448A",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}